const config = {
  apiGateway: {
    REGION: 'us-east-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://1mabmp5qdk.execute-api.us-east-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://ghi41zvgy4.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://jdgwm3hi73.execute-api.us-east-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.testing.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0y3pdsom96.execute-api.us-east-1.amazonaws.com/testing'
  },
};

export default config;